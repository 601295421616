<template>
  <b-container fluid>

    <b-row class="mb-4">
      <b-col class="mb-3 mb-md-0" md="4">
        <h1 class="font-weight-bold mb-0 text-primary">Impression Kits</h1>
      </b-col>
      <b-col class="mb-3 mb-md-0" md="8">
        <DateRangePicker :from="fromDate" :to="toDate" @input="input => { this.fromDate = input.date1; this.toDate = input.date2; this.doFetchTimeToSaleImpressionKits() }"></DateRangePicker>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">Lowest Time To Sale</h4>
          <h2 class="mb-0 text-muted" v-if="!isLoading">{{ relevantSingleProp.lowestTimeToSale }}</h2>
          <b-spinner v-if="isLoading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">Average Time To Sale</h4>
          <h2 class="mb-0 text-muted" v-if="!isLoading">{{ relevantSingleProp.averageTimeToSale }}</h2>
          <b-spinner v-if="isLoading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">Greatest Time To Sale</h4>
          <h2 class="mb-0 text-muted" v-if="!isLoading">{{ relevantSingleProp.greatestTimeToSale }}</h2>
          <b-spinner v-if="isLoading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import DateRangePicker from '../../components/DateRangePicker'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import numberMixin from '../../mixins/numberMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, momentMixin, numberMixin],
  data: () => ({
    fromDate: null,
    toDate: null
  }),
  created () {
    this.fromDate = this.moment().startOf('month').toDate()
    this.toDate = this.moment().endOf('month').toDate()
    this.doFetchTimeToSaleImpressionKits()
  },
  components: {
    FriendlyDate,
    DateRangePicker
  },
  computed: {
    ...mapGetters('time-to-sale', ['single', 'isLoading']),

    relevantSingleProp() {
      return this.single.impression_kits
    }
  },
  methods: {
    ...mapActions('time-to-sale', ['fetchImpressionKitTimeToSale']),

    doFetchTimeToSaleImpressionKits() {
      this.fetchImpressionKitTimeToSale({
        from: this.moment(this.fromDate).format('DD/MM/YYYY'),
        to: this.moment(this.toDate).format('DD/MM/YYYY'),
      })
    }
  },
}
</script>

<style lang="scss">

.tabs {

  .card-header {
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  .tab-content {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: unset;
  }

}



</style>
